<template>
  <div>
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="organization">
        <div class="man-title">
          供应商品牌
          <el-button @click="add" class="btn" type="primary" size="mini">新增品牌</el-button>
        </div>
        <div class="table-tree">
          <div class="tree">
            <div class="tree-title"><i class="iconfont icon-gongyingshang1" style="margin-right:10px;"></i>供应商</div>
            <el-tree
              :data="treeData"
              node-key="supplierCode"
              @node-click="treeRowClick"
              :props="defaultProps"
              :expand-on-click-node="false"
              highlight-current
              :current-node-key="supplierCode"
              default-expand-all
              ref="tree">
              <span class="custom-tree-node" slot-scope="{ node }">
                <span style="margin-right: 40px"><i class="iconfont icon-gongyingshang"></i> {{ node.label }}</span>
              </span>
            </el-tree>
          </div>
          
          <div class="table">
            <el-table :data="tableData" style="width: 100%" v-loading="tableLoading" element-loading-spinner="el-icon-loading">
              <el-table-column label="序号" type="index" width="50"></el-table-column>
              <el-table-column prop="deviceTypeCode" label="设备类型">
                <template slot-scope="scope">
                  <span v-show="scope.row.deviceTypeCode=='Direct'">直连设备</span>
                  <span v-show="scope.row.deviceTypeCode=='GatewaySub'">网关子设备</span>
                  <span v-show="scope.row.deviceTypeCode=='Gateway'">网关</span>
                </template>
              </el-table-column>
              <el-table-column prop="equipmentBrand" label="设备品牌"></el-table-column>
              <!-- <el-table-column label="操作" width="180">
                <template slot-scope="scope">
                  <el-dropdown placement="bottom-start" trigger="click">
                    <span @click.stop="">
                      <span class="el-dropdown-link">
                        操作<i class="el-icon-arrow-down"></i>
                      </span>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item><div @click="editModel(scope.row)">编辑</div></el-dropdown-item>
                      <el-dropdown-item><div @click="dltModel(scope.row)">删除</div></el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </template>
              </el-table-column> -->
            </el-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      tableLoading: false,
      createModelDrawer: false,
      editModelDrawer: false,
      form: {},
      editModelForm: {},
      rules: {
        attributeContent: [{ required: true, message: '必填项', trigger: 'blur' }]
      },
      treeData: [],
      tableData: [],
      supplierCode: '',
      defaultProps: {
        children: 'childTree',
        label: 'supplier'
      }
    }
  },
  methods: {
    // 切换字典
    treeRowClick(data) {
      this.supplierCode = data.supplierCode
      sessionStorage.setItem('supplierCode',data.supplierCode)
      this.loadPropertyRight(data.supplierCode)
    },

    // 新增
    add() {
      this.$router.push({ name: 'iotSupplierDeviceAdd' })
    },

    // 删除名称
    dltModel(row) {
      this.$confirm('确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then((res) => {
        this.deletePropertyRight(row.id)
      })
    },

    // 获取供应商
    loadSupplier() {
      this.$ajax.post('iotSupplierQuery').then(res => {
        this.treeData = res.data
        if(sessionStorage.getItem('supplierCode')){
          let supplierCode = sessionStorage.getItem('supplierCode')
          this.supplierCode = supplierCode
          setTimeout(()=>{
            this.$refs.tree.setCurrentKey(supplierCode)
          },300)
          this.loadPropertyRight(supplierCode)
        }else{
          this.supplierCode = this.treeData[0].supplierCode
          this.loadPropertyRight(this.supplierCode)
        }
      })
    },

    loadPropertyRight(supplierCode) {
      this.loading = true
      this.$ajax.post('supplierDeviceQuery',{
        supplierCode: supplierCode
      }).then(res => {
        this.tableData = res.data
        this.loading = false
      }).catch(err=>{
        this.loading = false
      })
    },
    deletePropertyRight(id) {
      this.loading = true
      this.$ajax.post('meterAttrDelete', {
        id: id
      }).then(res => {
        this.loading = false
        this.loadPropertyRight(this.supplierCode)
        this.$message.success('删除成功')
      }).catch(err=>{
        this.loading = false
        this.$message.error('删除失败')
      })
    },
  },
  mounted() {
    this.loadSupplier()
  },
  destroyed() {
    // sessionStorage.removeItem('supplierCode')
  }
}
</script>

<style lang="less" scoped>
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.organization {
  height: 100%;
}
.btn {
  float: right;
}
.table-tree {
  display: flex;
  height: ~'calc(100% - 56px)';
  .tree {
    width: 300px;
    border-right: 4px solid #f6f7fb;
    margin-right: 20px;
  }
  .table {
    flex: 1;
  }
  .operate {
    
    &:hover {
      color: #409EFF;
    }
  }
  .custom-tree-node {
    font-size: 14px;
  }
  
}
.company-name {
  font-size: 18px;
  padding: 10px 0;
  margin: 0 10px;
  line-height: 28px;
  i {
    margin-left: 20px;
    cursor: pointer;
  }
}
.add-box {
  padding: 14px;
  background: #eee;
  margin-bottom: 20px;
  line-height: 28px;
  overflow: hidden;
  border: 1px solid #b1b1b1;
  img {
    vertical-align: text-bottom;
  }
  .btn {
    float: right;
  }
}
/deep/.el-tree--highlight-current
  .el-tree-node.is-current
  > .el-tree-node__content {
  background-color: #409eff;
  color: white;
}
</style>

<style lang="less">
.organization {
  .el-tree-node__content {
    height: 40px;
  }
}
</style>